import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Spinner,
  Badge,
  Modal,
  ModalBody,
  UncontrolledDropdown,
  Alert,
} from "reactstrap";
import DataCardAnalytics from "../components/partials/default/DataCardAnalytics";
import {
  Block,
  BlockHead,
  BlockTitle,
  BlockBetween,
  BlockDes,
  BlockHeadContent,
  Row,
  Col,
  Icon,
  Button,
  PreviewCard,
  PaginationComponent,
  DataTablePagination,
} from "../components/Component";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import CountUp from "react-countup";
// import { straightLineChart } from "../pages/components/charts/ChartData";
// import HistoryMap from "./components/CustomWidgets/Widgets/HistoryMap";
import CustomWidgets from "./components/CustomWidgets/CustomWidgets";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import axios from "axios";
import Speedometer, { Arc, Background, Needle, Progress, Marks, Indicator } from "react-speedometer";
import TripsTable from "../components/table/TripsTable";
import TeltonikaTripsTable from "../components/table/TeltonikaTripsTable";
import RidersListTable from "../components/table/RidersListTable";
import LiveMapTeltonika from "./components/CustomWidgets/Widgets/LiveMapTeltonika";
import TimeDifference from "../components/TimeDifference";
import DatePicker from "react-datepicker";
import Lottie from "lottie-react";
import animationData from "../images/busMovement.json";
import altitudeAnimation from "../images/Altitude.json";
import satelliteAnimation from "../images/satellite.json";
import gsmAnimation from "../images/GSM.json";
import temperatureAnimation from "../images/DeviceTemp.json";
import "../pages/total.css";
import { Line } from "react-chartjs-2";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { selectSavedValue } from "../redux/sessionClientSlice";
import { useForm } from "react-hook-form";
import { user_id } from "../redux/userSlice";
import { role } from "../redux/userSlice";
import DataTable from "react-data-table-component";

import redBus2 from "../assets/images/redBusNew.png";
import greenBus from "../assets//images/greenBus.png";
import yellowBus from "../assets//images/yellowBus.png";
import blackBus from "../assets//images/blackBus.png";

const SpeedometerWiddget = ({ value }) => {
  return (
    <div style={{ height: 155, width: 150 }}>
      <Speedometer value={value} max={120} angle={240} min={0} height={80} width={150}>
        <Background opacity={0.8} />
        <Arc />
        <Needle />
        <Progress />
        <Marks step={10} />
        <Indicator fontSize={35} />
      </Speedometer>
    </div>
  );
};
const SpeedometerWidget = ({ value }) => {
  return (
    <div style={{ height: 155, width: 150 }}>
      <Speedometer value={value} max={15} angle={180} min={0} height={150} width={150}>
        <Background opacity={0.8} />
        <Arc />
        <Needle />
        <Progress />
        <Marks />
        <Indicator fontSize={35} />
      </Speedometer>
    </div>
  );
};

const ExpandableRowComponent = ({ data }) => {
  const vehiclesData = data;
  console.log("vehicle data", vehiclesData);
  return (
    <ul className="dtr-details p-2 border-bottom ml-1">
      <li style={{ padding: "0.25rem", borderBottom: "1px solid #ebeef2" }}>
        <span style={{ marginRight: "1rem" }} className=" dtr-title">
          Origin
        </span>{" "}
        <span className="dtr-data">{vehiclesData.origin}</span>
      </li>
      <li style={{ padding: "0.25rem", borderBottom: "1px solid #ebeef2" }}>
        <span style={{ marginRight: "1rem" }} className=" dtr-title">
          Destination
        </span>{" "}
        <span className="dtr-data">{vehiclesData.destination}</span>
      </li>
    </ul>
  );
};

const VehicleInfo = () => {
  const location = useLocation();
  const username = localStorage.getItem("username");
  const history = useHistory();
  const initialDate = new Date();
  const [startDate, setStartDate] = useState(new Date());
  const [isLoading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [viewOption, setViewOption] = useState("realtime");
  const [gpsData, setGpsData] = useState();
  const [analyticsData, setAnalyticsData] = useState();
  const [tripsData, setTripsData] = useState();
  const [teltonikaTripsData, setTeltonikaTripsData] = useState();
  const [gsmSignal, setGsmSignal] = useState(null);
  const [gpsSignal, setGpsSignal] = useState(null);
  const [altitudeData, setAltitudeData] = useState(null);
  const [tempData, setTempData] = useState(null);
  const [tofData, setTofData] = useState(null);

  const [timeStamp, setTimeStamp] = useState();
  const [occupancyData, setOccupancyData] = useState(null);
  const [occupancyList, setOccupancyList] = useState([]);
  const [totalMiles, setTotalMiles] = useState(0);

  const [currTime, setCurrTime] = useState(new Date());
  const [currSatellites, setCurrSatellites] = useState(0);
  const [currAltitude, setCurrAltitude] = useState(0);
  const [currTemp, setCurrTemp] = useState(0);
  const [currGSM, setCurrGSM] = useState(0);
  const [GSMStatus, setGSMStatus] = useState("No Signal");
  const [GSMColor, setGSMColor] = useState("secondary");

  const [auOverview, setAuOverview] = useState("Live");

  const imei = location.state?.imei;
  const vehicleType = location.state?.vehicleType;
  const vehicleNo = location.state?.vehicleNo;
  const DeviceType = location.state?.DeviceType;
  const DeviceMode = location.state?.DeviceMode;
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");

  const hours = String(today.getHours()).padStart(2, "0");
  const minutes = String(today.getMinutes()).padStart(2, "0");
  const seconds = String(today.getSeconds()).padStart(2, "0");
  const milliseconds = today.getMilliseconds();

  const [rowsPerPageS, setRowsPerPage] = useState(7);
  const [expandData, setExpandData] = useState(false);

  let busIcon;
  let busColor;
  switch (DeviceMode) {
    case "MOVING":
      busIcon = greenBus;
      busColor = "success";
      break;
    case "IDLE":
      busIcon = blackBus;
      busColor = "dark";
      break;
    case "PARKED":
      busIcon = yellowBus;
      busColor = "warning";
      break;
    case "OFFLINE":
      busIcon = redBus2;
      busColor = "danger";
    default:
      busIcon = redBus2;
      busColor = "danger";
      break;
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCheckboxClick = () => {
    setExpandData(!expandData);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
  };

  const [viewmore, setViewMore] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [error, setError] = useState(false);

  const clientValueId = useSelector(selectSavedValue);
  const client_id = useSelector(user_id);

  const userrole = useSelector(role);

  const [data, setData] = useState([]);
  const [itemPerPage] = useState(7);
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const redirectRun = () => {
    // console.log(formFields[0].driver_id);
    history.push("/fleet-tracker");
  };

  const date = new Date();

  const daysAgo = new Date(date.getTime());
  daysAgo.setDate(date.getDate() - 7);

  const [rangeDate, setRangeDate] = useState({
    start: daysAgo,
    end: new Date(),
  });

  const onRangeChange = (dates) => {
    const [start, end] = dates;
    setRangeDate({ start: start, end: end });
  };

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  console.log(vehicleNo);
  const gpschart = {
    labels: timeStamp,
    dataUnit: "People",
    datasets: [
      {
        label: "Satellite Count",
        color: "#9d72ff",
        borderWidth: 2,
        lineTension: 0,
        dash: 0,
        borderColor: "#1270f2",
        backgroundColor: "#bdd9ff",
        borderCapStyle: "square",
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#9d72ff",
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 2,
        pointRadius: 4,
        pointHitRadius: 4,
        data: gpsSignal,
      },
    ],
  };
  const gsmChart = {
    labels: timeStamp,
    dataUnit: "numbers",
    datasets: [
      {
        label: "GSM Signal Strength",
        data: gsmSignal,
        backgroundColor: "#fcbdc9",
        borderColor: "#D83A56",
        borderWidth: 2,
        fill: true,
        pointBorderWidth: 2,
        pointBackgroundColor: "white",
        pointRadius: 4,
      },
    ],
  };

  const resetForm = () => {
    //setFormData({});
    setSelectedItem({});
  };

  const { errors, register } = useForm();
  const [sortOrder, setSortOrder] = useState("desc");

  const onFormCancel = () => {
    //setView(false);
    setViewMore(false);
    //setRefund(false);

    resetForm();
  };

  // const handleSort = (columnName) => {
  //   const sortOrderToggle = sortOrder === "asc" ? "desc" : "asc";
  //   setSortOrder(sortOrderToggle);

  //   const sortedData = [...mainData].sort((a, b) => {
  //     if (columnName === "intime" || columnName === "outtime") {
  //       if (sortOrderToggle === "asc") {
  //         return new Date(a[columnName]) - new Date(b[columnName]);
  //       } else {
  //         return new Date(b[columnName]) - new Date(a[columnName]);
  //       }
  //     } else {
  //       return a[columnName] > b[columnName] ? 1 : -1;
  //     }
  //   });

  //   setData(sortedData);
  //   setCurrentPage(1);
  // };

  // const renderSortIcon = (columnName) => {
  //   if (sortOrder === "asc") {
  //     return <Icon name="arrow-up" />;
  //   } else if (sortOrder === "desc") {
  //     return <Icon name="arrow-down" />;
  //   }
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (rangeDate.start && rangeDate.end) {
          const formattedStartDate = new Date().toISOString().split("T")[0];
          const formattedEndDate = new Date().toISOString().split("T")[0];

          const decodedIMEI = decodeURIComponent(imei).toLowerCase();

          let url;
          if (userrole === 3 && clientValueId) {
            url = `${process.env.REACT_APP_FLEET_URL}api/ZIGShuttle/GetUserInOutDataMac?Startdate=${formattedStartDate}&Enddate=${formattedEndDate}&Clientid=${clientValueId}&Imei=${decodedIMEI}`;
          } else {
            url = `${process.env.REACT_APP_FLEET_URL}api/ZIGShuttle/GetUserInOutDataMac?Startdate=${formattedStartDate}&Enddate=${formattedEndDate}&Clientid=${client_id}&Imei=${decodedIMEI}`;
          }

          setLoading(true);
          const response = await fetch(url);
          setLoading(false);

          if (response.ok) {
            const json = await response.json();
            const sortedData = json.sort((a, b) => new Date(b.outtime) - new Date(a.outtime));
            const filteredData = sortedData.filter((item) => item.ticketId !== "IN RANGE");
            setData(filteredData);
          } else {
            console.error("Failed to fetch data:", response.statusText);
            setLoading(false);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [rangeDate]);

  // useEffect(() => {
  //   async function getChartData() {
  //     let response;
  //     if (username === "ZED Demo" || username === "testdemo") {
  //       response = await fetch(`https://panel-config.zed-admin.com/api/strength?imei=` + imei);
  //     } else {
  //       response = await fetch(`${process.env.REACT_APP_CONFIG_BASE_URL}strength?imei=` + imei);
  //     }

  //     const json = await response.json();
  //     if (response.status === 200 && json) {
  //       const timeArray = json.Time_Stamp.map((dateString) => {
  //         return dateString.split(" ")[1];
  //       });
  //       setGpsSignal(json.GPS);
  //       setGsmSignal(json.GSM);
  //       // setTimeStamp(timeArray);
  //     } else {
  //       setGpsSignal(null);
  //       setGsmSignal(null);
  //     }
  //   }

  //   getChartData();
  // }, [imei, username]);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("https://ecolane-api.zig-web.com/api/AllTripdata?WifiMacAddress=100");
      const json = await response.json();
      setTripsData(json);
    }
    fetchData();
  }, []);

  const customAxios = axios.create({
    baseURL: `${process.env.REACT_APP_FLEET_URL}`,
    timeout: 5000,
  });

  useEffect(() => {
    const getGpsData = async () => {
      try {
        let response;
        if (username === "ZED Demo" || username === "testdemo") {
          response = await axios.get(`https://panel-config.zed-admin.com/api/getlastGpsdata?imei=` + imei);
        } else {
          response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}getlastGpsdata?imei=` + imei);
        }
        return response.data;
      } catch (err) {
        throw err;
      }
    };
    // setLoading(true);
    const fetchData = () => {
      // setLoading(true);
      getGpsData()
        .then((res) => {
          setGpsData({ ...res });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          // setLoading(false);
          setInitialLoading(false);
        });
    };

    fetchData();

    const timer = setInterval(fetchData, 5000);

    return () => clearInterval(timer);
  }, [imei, username]);

  useEffect(() => {
    const getOccupancyData = async () => {
      try {
        const startDate = formatDate(rangeDate.start);
        const endDate = formatDate(rangeDate.end);

        let response;

        // Check if username has 'zig'
        if (username.includes("zig")) {
          response = await axios.get(
            `https://gps.zig-app.com/api/get/mac_data?start=${startDate}&end=${endDate}&imei=${imei}`
          );
        } else {
          if (username === "ZED Demo" || username === "testdemo") {
            response = await axios.get(
              `https://panel-config.zed-admin.com/api/GetUserCrowddata/mdot?Startdate=${startDate}&Enddate=${endDate}&beaconid=${imei}`
            );
          } else {
            response = await axios.get(
              `${process.env.REACT_APP_CONFIG_BASE_URL}GetUserCrowddata/mdot?Startdate=${startDate}&Enddate=${endDate}&beaconid=${imei}`
            );
          }
        }

        console.log(response.data);
        if (response.data.userData !== null) {
          setOccupancyList([...response.data.userData]);
        }
        setOccupancyData(response.data);
      } catch (err) {
        console.error(err);
        setOccupancyData(null);
      } finally {
        setLoading(false);
      }
    };

    const formatDate = (date) => {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
        .getDate()
        .toString()
        .padStart(2, "0")}`;
      return formattedDate;
    };

    setLoading(true);

    getOccupancyData();

    const timer = setInterval(() => {
      getOccupancyData();
    }, 30000);
    return () => clearInterval(timer);
  }, [rangeDate]);

  useEffect(() => {
    const getAnalyticsData = async () => {
      try {
        let response;
        if (username === "ZED Demo" || username === "testdemo") {
          response = await axios.get(`https://panel-config.zed-admin.com/api/getAnalyticsData?imei=` + imei);
        } else {
          response = await axios.get(`${process.env.REACT_APP_CONFIG_BASE_URL}getAnalyticsData?imei=` + imei);
        }

        setTotalMiles(response.data.total_odo);

        if (response.data.other_data.length > 0) {
          const otherData = response.data.other_data;
          const altitude = otherData.map((item) => parseInt(item.altitude) / 100);
          const gps = otherData.map((item) => parseInt(item.gps_strength));
          const gsm = otherData.map((item) => parseInt(item.gsm_strength));
          const temperature = otherData.map((item) => parseFloat(item.temperature).toFixed(2));
          const dates = otherData.map((item) => item.timestamp.split(" ")[1]);

          setCurrAltitude(otherData[otherData.length - 1].altitude);
          setCurrTemp(otherData[otherData.length - 1].temperature);
          setCurrSatellites(otherData[otherData.length - 1].gps_strength);
          setCurrGSM(otherData[otherData.length - 1].gsm_strength);
          setCurrTime(otherData[otherData.length - 1].timestamp.split(" ")[1]);

          // Number----------------- Status

          // 0 --------------------- No signal--------------------Default
          // 1 --------------------- Poor Signal----------------- RED
          // 2 - 30 ----------------good Signal----------------GREEN
          // 31 --------------------Excellent Signal----------- GREEN
          // 99 --------------------Not Detectable----------- Default

          if (parseInt(otherData[otherData.length - 1].gsm_strength) === 0) {
            setGSMStatus("No Signal");
            setGSMColor("secondary");
          } else if (parseInt(otherData[otherData.length - 1].gsm_strength) === 1) {
            setGSMStatus("Poor Signal");
            setGSMColor("danger");
          } else if (
            parseInt(otherData[otherData.length - 1].gsm_strength) >= 2 &&
            parseInt(otherData[otherData.length - 1].gsm_strength) <= 30
          ) {
            setGSMStatus("Good Signal");
            setGSMColor("success");
          } else if (parseInt(otherData[otherData.length - 1].gsm_strength) === 31) {
            setGSMStatus("Excellent Signal");
            setGSMColor("success");
          } else if (parseInt(otherData[otherData.length - 1].gsm_strength) === 99) {
            setGSMStatus("Not Detectable");
            setGSMColor("secondary");
          } else {
            setGSMStatus("No Signal");
            setGSMColor("secondary");
          }

          setGpsSignal(gps);
          setGsmSignal(gsm);
          setTimeStamp(dates);

          setAltitudeData({
            labels: dates,
            dataUnit: "People",
            datasets: [
              {
                label: "Altitude",
                color: "#9d72ff",
                borderWidth: 2,
                borderColor: "#597445",
                backgroundColor: "#b5c9a5",
                borderCapStyle: "square",
                pointBorderColor: "transparent",
                pointBackgroundColor: "transparent",
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: "#9d72ff",
                pointBorderWidth: 2,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 2,
                pointRadius: 4,
                pointHitRadius: 4,
                data: altitude,
                borderDash: [5, 5],
              },
            ],
          });
          setTempData({
            labels: dates,
            dataUnit: "People",
            datasets: [
              {
                label: "Temperature",
                color: "#9d72ff",
                borderWidth: 2,
                borderColor: "#EB5B00",
                backgroundColor: "#ffcfb0",
                borderCapStyle: "square",
                pointBorderColor: "transparent",
                pointBackgroundColor: "transparent",
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: "#9d72ff",
                pointBorderWidth: 2,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 2,
                pointRadius: 4,
                pointHitRadius: 4,
                data: temperature,
                borderDash: [5, 5],
              },
            ],
          });
        } else {
          setAltitudeData(null);
          setGsmSignal(null);
          setGpsSignal(null);
          setTempData(null);
        }

        return response.data;
      } catch (err) {
        throw err;
      }
    };

    setLoading(true);
    getAnalyticsData()
      .then((res) => {
        setAnalyticsData({ ...res });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [imei, username]);

  useEffect(() => {
    const getTofData = async () => {
      try {
        let response;
        if (vehicleNo === "B865") {
          response = await axios.get(
            `${process.env.REACT_APP_CONFIG_BASE_URL}panel/tof_data?startDate=${rangeDate.start
              .toISOString()
              .slice(0, 10)}&endDate=${rangeDate.end.toISOString().slice(0, 10)}&mac=${imei}`
          );
        } else {
          setTofData(null);
          return;
        }

        if (response.status === 200) {
          const tofData = response.data.data;
          const sortedData = tofData.sort((a, b) => new Date(a.added_datetime) - new Date(b.added_datetime));
          console.log("Sorted data:", sortedData);
          const count = tofData.map((item) => item.count);
          const dates = tofData.map((item) => new Date(item.added_datetime));

          setTofData({
            labels: dates,
            dataUnit: "People",
            datasets: [
              {
                label: "Count",
                color: "#9d72ff",
                borderWidth: 2,
                borderColor: "#421257",
                backgroundColor: "#cf7af5",
                borderCapStyle: "square",
                pointBorderColor: "transparent",
                pointBackgroundColor: "transparent",
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: "#9d72ff",
                pointBorderWidth: 2,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 2,
                pointRadius: 4,
                pointHitRadius: 4,
                data: count,
                borderDash: [5, 5],
              },
            ],
          });
        } else {
          setTofData(null);
        }

        return response.data;
      } catch (err) {
        throw err;
      }
    };

    getTofData();
  }, [rangeDate.end]);

  useEffect(() => {
    async function fetchTeltonikaTripsData() {
      const current = new Date();
      const currentdate = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;
      console.log(currentdate);
      // const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

      const response = await axios.get(
        `${process.env.REACT_APP_CONFIG_BASE_URL}getTripList?imei=${imei}&date=${currentdate}`
      );

      // const json = await response.json();
      const json = response.data;
      const filteredArray = json.filter((obj) => {
        for (const key in obj) {
          if (obj[key] === null) {
            return false;
          }
        }
        return true;
      });
      setTeltonikaTripsData(filteredArray);
    }
    fetchTeltonikaTripsData();
    // console.log(fetchTeltonikaTripsData);
  }, []);

  if (!imei) return <Redirect to="/" />;

  const onViewClick = async (item) => {
    try {
      console.log("item", item);
      setViewMore(true);
      setSelectedItem(item);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {

  // },[])
  return (
    <React.Fragment>
      <Head title="Add Tracker"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Coach Info
              </BlockTitle>
              {/* 
                <BlockDes className="text-soft">
                  <ul className="list-inline">
                    <li>
                      Coach No: <span className="text-base">{vehicleNo}</span>
                    </li>
                    <li>
                      IMEI: <span className="text-base">{gpsData?.imei ? gpsData?.imei : 0}</span>
                    </li>
                    <li>
                      Last updated:
                      <span className="text-base">
                        {<TimeDifference timestamp={gpsData?.timestamp} />}
                      </span>
                    </li>
                  </ul>
                </BlockDes> */}
            </BlockHeadContent>
            <BlockHeadContent>
              <Button color="light" onClick={() => redirectRun()} outline className="bg-white d-none d-sm-inline-flex">
                <Icon name="arrow-left"></Icon>
                <span>Back</span>
              </Button>
              <Button color="light" outline className="btn-icon bg-white d-inline-flex d-sm-none">
                <Icon name="arrow-left"></Icon>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="g-gs">
            {/* <Col sm="3">
              <div>
                <div>
                  <div className="picker" style={{ width: "15vw" }}>
                  <DatePicker
                    selected={rangeDate.start}
                    startDate={rangeDate.start}
                    onChange={onRangeChange}
                    endDate={rangeDate.end}
                    selectsRange
                    className="form-control date-picker"
                  />
                </div>
                  <div>
                    <FormGroup>
                      <div className="form-control-wrap">
                        <div className="form-control-select">
                          <Input
                            type="select"
                            name="select"
                            id="view-options"
                            onChange={(event) => setViewOption(event.target.value)}
                          >
                            <option value="realtime">Realtime Tracking</option>
                          </Input>
                        </div>
                      </div>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </Col> */}
            <Col sm="3">
              <Card className="px-2 py-2 border border-primary" style={{ height: "75px" }}>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 className="text-primary mb-2">Vehicle Mode</h6>
                    <h6 style={{ fontWeight: "bold", fontSize: "15px", marginLeft: "-5px" }}>
                      <Badge color={busColor}>{DeviceMode}</Badge>
                    </h6>
                  </div>
                  <div>
                    <img style={{ width: "45px", height: "45px" }} src={busIcon} />
                  </div>
                </div>
              </Card>
            </Col>

            <Col sm="9">
              <div className="animation-container">
                <div className="total-count animated-div">
                  <div style={{ width: "120px", height: "120px", marginTop: "-20px" }}>
                    <Lottie animationData={animationData} loop autoplay speed={0.7} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {initialLoading ? (
            <Card className="mb-3">
              <div className="nk-ecwg nk-ecwg6">
                <div className="card-inner">
                  <div className="card-title-group" style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <h6 className="title " style={{ textAlign: "center" }}>
                        <Spinner color="primary" type="grow" />
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          ) : (
            <div style={{ marginTop: "-40px" }}>
              <Row className="g-gs">
                <Col sm="3">
                  <Card
                    className="py-3 px-2 d-flex align-items-center justify-content-center shadow"
                    style={{ height: "75px" }}
                  >
                    <h6 className="text-primary">Coach No</h6>
                    <h6 style={{ fontWeight: "bold", fontSize: "15px" }}> {vehicleNo}</h6>
                  </Card>
                </Col>
                <Col sm="3">
                  <Card
                    className="py-3 px-2 d-flex align-items-center justify-content-center shadow"
                    style={{ height: "75px" }}
                  >
                    <h6 className="text-primary">IMEI</h6>
                    <h6 style={{ fontWeight: "bold", fontSize: "15px" }}> {gpsData?.imei ? gpsData?.imei : 0}</h6>
                  </Card>
                </Col>
                <Col sm="3">
                  <Card
                    className="py-3 px-2 d-flex align-items-center justify-content-center shadow"
                    style={{ height: "75px" }}
                  >
                    <h6 className="text-primary">Last updated</h6>
                    <h6 style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {" "}
                      {<TimeDifference timestamp={gpsData?.timestamp} />}
                    </h6>
                  </Card>
                </Col>
                <Col sm="3">
                  <Card
                    className="py-3 px-2 d-flex align-items-center justify-content-center shadow"
                    style={{ height: "75px" }}
                  >
                    <h6 className="text-primary">Total Miles</h6>
                    <h6 style={{ fontWeight: "bold", fontSize: "15px" }}>{(totalMiles * 0.621371).toFixed(2)}</h6>
                  </Card>
                </Col>
              </Row>
            </div>
          )}

          <Row className="gy-5">
            <Col sm="3">
              <Card>
                <CardBody className="card-inner">
                  <CardTitle tag="h6" className="center">
                    Speed (mph)
                  </CardTitle>
                  <div className="center">
                    <SpeedometerWiddget
                      value={DeviceMode !== "PARKED" && DeviceMode !== "OFFLINE" ? (gpsData?.speed ?? 0) * 0.621371 : 0}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="3">
              <Card>
                <CardBody className="card-inner">
                  <CardTitle className="center" tag="h6">
                    Battery Voltage (Volts)
                  </CardTitle>
                  <div className="center">
                    <SpeedometerWidget
                      value={DeviceMode !== "PARKED" && DeviceMode !== "OFFLINE" ? gpsData?.external_voltage : 0}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="3">
              <div>
                <Card
                  className="py-3 px-2 d-flex justify-content-center shadow"
                  style={{ height: "107px", width: "100%" }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h6 className="text-primary mb-3">Satellite Count</h6>
                      <h6 style={{ fontWeight: "bold", fontSize: "20px" }}>
                        {" "}
                        <CountUp className="rolling-number" duration={2} end={currSatellites} />
                      </h6>
                    </div>
                    <div style={{ width: "100px", height: "100px" }}>
                      <Lottie animationData={satelliteAnimation} loop autoplay speed={0.7} />
                    </div>
                  </div>
                </Card>
              </div>
              <div className="mt-3">
                <Card
                  className="py-3 px-2 d-flex justify-content-center shadow"
                  style={{ height: "107px", width: "100%" }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h6 className="text-primary mb-3">GSM Strength</h6>
                      <div className="d-flex align-items-start">
                        <h6 style={{ fontWeight: "bold", fontSize: "18px" }}>
                          <Badge pill color={GSMColor}>
                            {GSMStatus}
                          </Badge>
                        </h6>
                        <span className="ml-1" style={{ fontWeight: "bold", fontSize: "16px" }}>
                          ({currGSM})
                        </span>
                      </div>
                    </div>
                    <div style={{ width: "100px", height: "100px" }}>
                      <Lottie animationData={gsmAnimation} loop autoplay speed={0.7} />
                    </div>
                  </div>
                </Card>
              </div>
            </Col>

            <Col sm="3">
              <div>
                <Card
                  className="py-3 px-2 d-flex justify-content-center shadow"
                  style={{ height: "107px", width: "100%" }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h6 className="text-primary mb-3">Altitude (m)</h6>
                      <h6 style={{ fontWeight: "bold", fontSize: "20px" }}>
                        {" "}
                        <CountUp
                          className="rolling-number"
                          duration={2}
                          end={parseFloat(currAltitude) / 100}
                          decimals={2}
                          separator=""
                        />
                      </h6>
                    </div>
                    <div style={{ width: "70px", height: "70px" }}>
                      <Lottie animationData={altitudeAnimation} loop autoplay speed={0.7} />
                    </div>
                  </div>
                </Card>
              </div>
              <div className="mt-3">
                <Card
                  className="py-3 px-2 d-flex justify-content-center shadow"
                  style={{ height: "107px", width: "100%" }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h6 className="text-primary mb-3">SoC Temperature</h6>
                      <h6 style={{ fontWeight: "bold", fontSize: "20px" }}>
                        {" "}
                        <CountUp className="rolling-number" duration={2} end={currTemp} />
                        °C
                      </h6>
                    </div>
                    <div>
                      <div style={{ width: "50px", height: "50px" }}>
                        <Lottie animationData={temperatureAnimation} loop autoplay speed={0.7} />
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </Col>

            {/* <Col lg="4">
              <div>
                <div>
                  <Card
                    className="py-3 px-2 d-flex align-items-center justify-content-center border border-primary"
                    style={{ height: "105px", width: "100%" }}
                  >
                    <h6 className="text-primary">Total Miles</h6>
                    <h6 style={{ fontWeight: "bold", fontSize: "18px", marginTop: "5px" }}>
                      {" "}
                      {gpsData?.total_odometer ? parseFloat(gpsData?.total_odometer).toFixed(2) : 0}
                    </h6>
                  </Card>
                </div>
                <br />
                <div>
                  <Card
                    className="py-3 px-2 d-flex align-items-center justify-content-center border border-primary"
                    style={{ height: "105px", width: "100%" }}
                  >
                    <h6 className="text-primary">Tripmeter (miles)</h6>
                    <h6 style={{ fontWeight: "bold", fontSize: "18px", marginTop: "5px" }}>
                      {" "}
                      {gpsData?.today_odometer ? parseFloat(gpsData?.today_odometer).toFixed(2) : 0}
                    </h6>
                  </Card>
                </div>
              </div>
            </Col> */}
            {occupancyData && (DeviceType === 3 || DeviceType === 4) && occupancyData.activeCrowd !== null ? (
              <Col sm="12">
                {/* <CardTitle className="left" tag="h6">
                    Vehicle Tracking
                  </CardTitle> */}
                <div style={{ height: "400px", marginTop: "-15px" }}>
                  <LiveMapTeltonika
                    DeviceType={DeviceType}
                    count={occupancyData.count}
                    imei={imei}
                    vehicleType={vehicleType}
                    DeviceMode={DeviceMode}
                  />
                </div>
              </Col>
            ) : (
              <Col sm="12">
                {/* <Spinner color="primary" type="grow" /> */}
                <div style={{ height: "400px" }}>
                  <LiveMapTeltonika
                    DeviceType={DeviceType}
                    imei={imei}
                    vehicleType={vehicleType}
                    DeviceMode={DeviceMode}
                  />
                </div>
              </Col>
            )}

            {/* {viewOption === "realtime" ? ( */}
            {/* <Col sm="12">
                <div style={{ width: "100%", height: "522px" }}>
                  <LiveMapTeltonika count={100} imei={imei} vehicleType={vehicleType} />
                </div>
              </Col>
            ) : (
              <Col sm="12"> */}
            {/* <CustomWidgets gpsData={gpsData} /> */}
            {/* <div style={{ width: "100%", height: "522px" }}> */}
            {/* <HistoryMap imei={imei} vehicleType={vehicleType} /> */}
            {/* </div>
              </Col>
            )} */}
          </Row>
        </Block>
        <br></br>
        <Block className={"mt-2"}>
          <Row className="g-gs">
            <Col xxl="3" sm="4">
              <DataCardAnalytics
                title="Today Traveled (miles)"
                up={true}
                amount={gpsData?.today_odometer ? (parseFloat(gpsData?.today_odometer) * 0.621371).toFixed(2) : 0.0}
              />
            </Col>
            <Col xxl="3" sm="4">
              <DataCardAnalytics
                title="Average Speed (mph) "
                // percentChange={"2.5"}
                up={true}
                // chart={<DefaultOrderChart />}
                amount={analyticsData?.avgSpeed ? analyticsData?.avgSpeed : 0}
              />
            </Col>
            {DeviceType !== 4 && (
              <Col xxl="3" sm="4">
                <DataCardAnalytics
                  title="No of Stops"
                  // percentChange={"2.5"}
                  up={true}
                  // chart={<DefaultOrderChart />}
                  amount={analyticsData?.noOfStops ? analyticsData?.noOfStops : 0}
                />
              </Col>
            )}
            <Col xxl="3" sm="4">
              <DataCardAnalytics
                title="Top Speed (mph)"
                // percentChange={"2.5"}
                up={true}
                // chart={<DefaultOrderChart />}
                amount={analyticsData?.overSpeed ? analyticsData?.overSpeed : 0}
              />
            </Col>
            {DeviceType !== 4 && (
              <Col xxl="3" sm="4">
                <DataCardAnalytics
                  title="Moving hours"
                  // percentChange={"2.5"}
                  up={true}
                  // chart={<DefaultOrderChart />}
                  amount={analyticsData?.movingTime ? analyticsData?.movingTime : "0 hrs 0 mins"}
                />
              </Col>
            )}
            {DeviceType !== 4 && (
              <Col xxl="3" sm="4">
                <DataCardAnalytics
                  title="Air Conditioner"
                  // percentChange={"2.5"}
                  up={true}
                  // chart={<DefaultOrderChart />}

                  amount={
                    analyticsData?.ac_status ? (parseInt(analyticsData?.ac_status) <= 5000 ? "Off" : "On") : "Off"
                  } // amount={analyticsData.ac_status ? "0" : "not"}
                />
              </Col>
            )}
            {DeviceType !== 4 && (
              <Col xxl="3" sm="4">
                <DataCardAnalytics
                  title="Idling Hours"
                  // percentChange={"2.5"}
                  up={true}
                  // chart={<DefaultOrderChart />}

                  amount={analyticsData?.idling ? analyticsData?.idling : "0 hrs 0 mins"}
                />
              </Col>
            )}
            {DeviceType !== 4 && (
              <Col xxl="3" sm="4">
                <DataCardAnalytics
                  title="Built-In Battery (V)"
                  // percentChange={"2.5"}
                  up={true}
                  // chart={<DefaultOrderChart />}
                  amount={analyticsData?.buitBattery ? analyticsData?.buitBattery : 0}
                />
              </Col>
            )}
            {DeviceType !== 4 && (
              <Col xxl="3" sm="4">
                <DataCardAnalytics
                  title="External Battery (V)"
                  // percentChange={"2.5"}
                  up={true}
                  // chart={<DefaultOrderChart />}
                  amount={analyticsData?.extVoltage ? analyticsData?.extVoltage : 0}
                />
              </Col>
            )}

            {/* <Col xxl="3" sm="4">
              <DataCard
                title="Total Devices"
                percentChange={"2.5"}
                up={true}
                chart={<DefaultOrderChart />}
                amount={devices ? `${devices.length}` : "NA"}
              />
            </Col> */}
            {/* <Col xxl="3" sm="4">
              <DataCard
                title="Devices Online"
                percentChange={"2.5"}
                up={false}
                chart={<DefaultRevenueChart />}
                amount={devices ? `${devices.filter((i) => i.DeviceStatus === "Online").length}` : "NA"}
              />
            </Col> */}
          </Row>
        </Block>
        <Block>
          <Row>
            <Col md={6}>
              <PreviewCard>
                <div className="card-head">
                  <h6 className="title">Satellite Count</h6>
                </div>
                <div className="nk-ck-sm">
                  {/* <LineChartExample legend={false} data={gpschart} /> */}
                  <div style={{ height: "200px" }}>
                    {gpsSignal ? (
                      <Line
                        className="analytics-line-large"
                        data={gpschart}
                        options={{
                          legend: {
                            display: false,
                            labels: {
                              boxWidth: 12,
                              padding: 20,
                              fontColor: "#6783b8",
                            },
                          },
                          maintainAspectRatio: false,
                          tooltips: {
                            enabled: true,
                            backgroundColor: "#1c2b46",
                            titleFontSize: 13,
                            titleFontColor: "#fff",
                            titleMarginBottom: 6,
                            bodyFontColor: "#fff",
                            bodyFontSize: 12,
                            bodySpacing: 4,
                            yPadding: 10,
                            xPadding: 10,
                            footerMarginTop: 0,
                            displayColors: false,
                          },
                          scales: {
                            yAxes: [
                              {
                                display: true,
                                ticks: {
                                  beginAtZero: true,
                                  fontSize: 12,
                                  fontColor: "#9eaecf",
                                  padding: 8,
                                  stepSize: 100,
                                },
                                gridLines: {
                                  color: "rgba(82, 100, 132, 0.2)",
                                  tickMarkLength: 0,
                                  zeroLineColor: "rgba(82, 100, 132,0.2)",
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: "Count \u2192",
                                  fontSize: 12,
                                  fontColor: "#9eaecf",
                                },
                              },
                            ],
                            xAxes: [
                              {
                                display: true,
                                ticks: {
                                  fontSize: 12,
                                  fontColor: "#9eaecf",
                                },
                                gridLines: {
                                  display: false,
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: "Time \u2192",
                                  fontSize: 12,
                                  fontColor: "#9eaecf",
                                },
                              },
                            ],
                          },
                          elements: {
                            line: {
                              tension: 0,
                            },
                          },
                        }}
                      ></Line>
                    ) : (
                      <div className="text-center mt-2 pt-5 text-primary">
                        <b>No Live Data Found</b>
                      </div>
                    )}
                  </div>
                </div>
              </PreviewCard>
            </Col>
            {/* <Col md={6}>
              <PreviewCard>
                <div className="card-head">
                  <h6 className="title">GSM Signal Strength</h6>
                </div>
                <div className="nk-ck-sm">
                  <LineChartExample legend={false} data={gsmChart} />
                </div>
              </PreviewCard>
            </Col> */}
            <Col md={6}>
              <PreviewCard className={window.innerWidth <= 768 ? "mt-2" : ""}>
                <div className="card-head">
                  <h6 className="title">GSM Signal Strength</h6>
                </div>
                <div className="nk-ck-sm">
                  <div style={{ height: "200px" }}>
                    {gsmSignal ? (
                      <Line
                        className="analytics-line-large"
                        data={gsmChart}
                        options={{
                          legend: {
                            display: false,
                            labels: {
                              boxWidth: 12,
                              padding: 20,
                              fontColor: "#6783b8",
                            },
                          },
                          maintainAspectRatio: false,
                          tooltips: {
                            enabled: true,
                            backgroundColor: "#1c2b46",
                            titleFontSize: 13,
                            titleFontColor: "#fff",
                            titleMarginBottom: 6,
                            bodyFontColor: "#fff",
                            bodyFontSize: 12,
                            bodySpacing: 4,
                            yPadding: 10,
                            xPadding: 10,
                            footerMarginTop: 0,
                            displayColors: false,
                          },
                          scales: {
                            yAxes: [
                              {
                                display: true,
                                ticks: {
                                  beginAtZero: true,
                                  fontSize: 12,
                                  fontColor: "#9eaecf",
                                  padding: 8,
                                  stepSize: 50,
                                },
                                gridLines: {
                                  color: "rgba(82, 100, 132, 0.2)",
                                  tickMarkLength: 0,
                                  zeroLineColor: "rgba(82, 100, 132,0.2)",
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: "Count \u2192",
                                  fontSize: 12,
                                  fontColor: "#9eaecf",
                                },
                              },
                            ],
                            xAxes: [
                              {
                                display: true,
                                ticks: {
                                  fontSize: 12,
                                  fontColor: "#9eaecf",
                                },
                                gridLines: {
                                  display: false,
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: "Time \u2192",
                                  fontSize: 12,
                                  fontColor: "#9eaecf",
                                },
                              },
                            ],
                          },
                          elements: {
                            line: {
                              tension: 0,
                            },
                          },
                        }}
                      ></Line>
                    ) : (
                      <div className="text-center mt-2 pt-5 text-primary">
                        <b>No Live Data Found</b>
                      </div>
                    )}
                  </div>
                </div>
              </PreviewCard>
            </Col>
          </Row>
          <Row className={"mt-2"}>
            <Col md={6}>
              <PreviewCard>
                <div className="card-head">
                  <h6 className="title">Altitude (m)</h6>
                </div>
                <div className="nk-ck-sm">
                  <div style={{ height: "200px" }}>
                    {altitudeData ? (
                      <Line
                        className="analytics-line-large"
                        data={altitudeData}
                        options={{
                          legend: {
                            display: false,
                            labels: {
                              boxWidth: 12,
                              padding: 20,
                              fontColor: "#6783b8",
                            },
                          },
                          maintainAspectRatio: false,
                          tooltips: {
                            enabled: true,
                            backgroundColor: "#1c2b46",
                            titleFontSize: 13,
                            titleFontColor: "#fff",
                            titleMarginBottom: 6,
                            bodyFontColor: "#fff",
                            bodyFontSize: 12,
                            bodySpacing: 4,
                            yPadding: 10,
                            xPadding: 10,
                            footerMarginTop: 0,
                            displayColors: false,
                            callbacks: {
                              label: function (tooltipItem, data) {
                                // Format the value to two decimal places
                                let value = tooltipItem.yLabel;
                                return value.toFixed(2);
                              },
                            },
                          },
                          scales: {
                            yAxes: [
                              {
                                display: true,
                                ticks: {
                                  beginAtZero: true,
                                  fontSize: 12,
                                  fontColor: "#9eaecf",
                                  padding: 8,
                                  stepSize: 300,
                                },
                                gridLines: {
                                  color: "rgba(82, 100, 132, 0.2)",
                                  tickMarkLength: 0,
                                  zeroLineColor: "rgba(82, 100, 132,0.2)",
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: "Count \u2192",
                                  fontSize: 12,
                                  fontColor: "#9eaecf",
                                },
                              },
                            ],
                            xAxes: [
                              {
                                display: true,
                                ticks: {
                                  fontSize: 12,
                                  fontColor: "#9eaecf",
                                },
                                gridLines: {
                                  display: false,
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: "Time \u2192",
                                  fontSize: 12,
                                  fontColor: "#9eaecf",
                                },
                              },
                            ],
                          },
                          elements: {
                            line: {
                              tension: 0,
                            },
                          },
                        }}
                      ></Line>
                    ) : (
                      <div className="text-center mt-2 pt-5 text-primary">
                        <b>No Live Data Found</b>
                      </div>
                    )}
                  </div>
                </div>
              </PreviewCard>
            </Col>
            <Col md={6}>
              <PreviewCard className={window.innerWidth <= 768 ? "mt-2" : ""}>
                <div className="card-head">
                  <h6 className="title">SoC Temperature (°C)</h6>
                </div>
                <div className="nk-ck-sm">
                  <div style={{ height: "200px" }}>
                    {tempData ? (
                      <Line
                        className="analytics-line-large"
                        data={tempData}
                        options={{
                          legend: {
                            display: false,
                            labels: {
                              boxWidth: 12,
                              padding: 20,
                              fontColor: "#6783b8",
                            },
                          },
                          maintainAspectRatio: false,
                          tooltips: {
                            enabled: true,
                            backgroundColor: "#1c2b46",
                            titleFontSize: 13,
                            titleFontColor: "#fff",
                            titleMarginBottom: 6,
                            bodyFontColor: "#fff",
                            bodyFontSize: 12,
                            bodySpacing: 4,
                            yPadding: 10,
                            xPadding: 10,
                            footerMarginTop: 0,
                            displayColors: false,
                          },
                          scales: {
                            yAxes: [
                              {
                                display: true,
                                ticks: {
                                  beginAtZero: true,
                                  fontSize: 12,
                                  fontColor: "#9eaecf",
                                  padding: 8,
                                  stepSize: 80,
                                },
                                gridLines: {
                                  color: "rgba(82, 100, 132, 0.2)",
                                  tickMarkLength: 0,
                                  zeroLineColor: "rgba(82, 100, 132,0.2)",
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: "Count \u2192",
                                  fontSize: 12,
                                  fontColor: "#9eaecf",
                                },
                              },
                            ],
                            xAxes: [
                              {
                                display: true,
                                ticks: {
                                  fontSize: 12,
                                  fontColor: "#9eaecf",
                                },
                                gridLines: {
                                  display: false,
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: "Time \u2192",
                                  fontSize: 12,
                                  fontColor: "#9eaecf",
                                },
                              },
                            ],
                          },
                          elements: {
                            line: {
                              tension: 0,
                            },
                          },
                        }}
                      ></Line>
                    ) : (
                      <div className="text-center mt-2 pt-5 text-primary">
                        <b>No Live Data Found</b>
                      </div>
                    )}
                  </div>
                </div>
              </PreviewCard>
            </Col>
          </Row>
          <br />
          {vehicleNo === "B865" && (
            <Row className="g-gs">
              <Col sm={12}>
                <div style={{ width: "200px" }} className="mb-1">
                  <BlockTitle page tag="h3">
                    <DatePicker
                      selected={rangeDate.start}
                      startDate={rangeDate.start}
                      onChange={onRangeChange}
                      endDate={rangeDate.end}
                      selectsRange
                      className="form-control date-picker"
                    />
                  </BlockTitle>
                </div>
                <PreviewCard className={window.innerWidth <= 768 ? "mt-2" : ""}>
                  <div style={{ height: "400px" }}>
                    <div className="nk-ck-sm">
                      <div className="card-head">
                        <h6 className="title">Occupancy Metrics</h6>
                      </div>
                      <div style={{ height: "380px" }}>
                        {tofData ? (
                          <Line
                            className="analytics-line-large"
                            data={tofData}
                            options={{
                              legend: {
                                display: false,
                                labels: {
                                  boxWidth: 12,
                                  padding: 20,
                                  fontColor: "#6783b8",
                                },
                              },
                              maintainAspectRatio: false,
                              tooltips: {
                                enabled: true,
                                backgroundColor: "#1c2b46",
                                titleFontSize: 13,
                                titleFontColor: "#fff",
                                titleMarginBottom: 6,
                                bodyFontColor: "#fff",
                                bodyFontSize: 12,
                                bodySpacing: 4,
                                yPadding: 10,
                                xPadding: 10,
                                footerMarginTop: 0,
                                displayColors: false,
                                callbacks: {
                                  title: function (tooltipItem, data) {
                                    let date = new Date(tooltipItem[0].label);

                                    // Format date as DD/MM/YYYY
                                    let day = date.getDate().toString().padStart(2, "0");
                                    let month = (date.getMonth() + 1).toString().padStart(2, "0");
                                    let year = date.getFullYear();

                                    // Format time as HH:MM AM/PM
                                    let hours = date.getHours();
                                    let minutes = date.getMinutes().toString().padStart(2, "0");
                                    let ampm = hours >= 12 ? "PM" : "AM";
                                    hours = hours % 12;
                                    hours = hours ? hours : 12; // Convert 0 hour to 12

                                    // Combine date and time
                                    return `${day}/${month}/${year}, ${hours}:${minutes} ${ampm}`;
                                  },
                                  label: function (tooltipItem, data) {
                                    return `Count: ${tooltipItem.value}`;
                                  },
                                },
                              },
                              scales: {
                                yAxes: [
                                  {
                                    display: true,
                                    ticks: {
                                      beginAtZero: true,
                                      fontSize: 12,
                                      fontColor: "#9eaecf",
                                      padding: 8,
                                      stepSize: 80,
                                    },
                                    gridLines: {
                                      color: "rgba(82, 100, 132, 0.2)",
                                      tickMarkLength: 0,
                                      zeroLineColor: "rgba(82, 100, 132,0.2)",
                                    },
                                    scaleLabel: {
                                      display: true,
                                      labelString: "Count \u2192",
                                      fontSize: 12,
                                      fontColor: "#9eaecf",
                                    },
                                  },
                                ],
                                xAxes: [
                                  {
                                    display: true,
                                    ticks: {
                                      fontSize: 12,
                                      fontColor: "#9eaecf",
                                      callback: function (value, index, values) {
                                        let date = new Date(value);
                                        let hours = date.getHours();
                                        let minutes = date.getMinutes().toString().padStart(2, "0");
                                        let ampm = hours >= 12 ? "PM" : "AM";
                                        hours = hours % 12;
                                        hours = hours ? hours : 12; // the hour '0' should be '12'
                                        return `${hours}:${minutes} ${ampm}`;
                                      },
                                    },
                                    gridLines: {
                                      display: false,
                                    },
                                    scaleLabel: {
                                      display: true,
                                      labelString: "Time \u2192",
                                      fontSize: 12,
                                      fontColor: "#9eaecf",
                                    },
                                  },
                                ],
                              },
                              elements: {
                                line: {
                                  tension: 0,
                                },
                              },
                            }}
                          ></Line>
                        ) : (
                          <div className="text-center mt-2 pt-5 text-primary">
                            <b>No Live Data Found</b>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </PreviewCard>
              </Col>
            </Row>
          )}
        </Block>
        {/* {
          DeviceType !== 3 && (
            <Block>
              <BlockHead>
                <BlockHeadContent>
                  <BlockTitle tag="h5">Riders List</BlockTitle>
                </BlockHeadContent>
              </BlockHead>
              <PreviewCard>
                {console.log("occupancyData:", occupancyData)}
                {console.log("occupancyData.userData:", occupancyData?.userData)}
                {occupancyData && occupancyData.userData && occupancyData.userData.length > 0 ? (
                  <RidersListTable data={occupancyData.userData} expandableRows pagination />
                ) : (
                  <p className="text-center">No rider data available.</p>
                )}
              </PreviewCard>
            </Block>
          )
        } */}

        {/* <Block>
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle tag="h5">Trips List</BlockTitle>
            </BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            {teltonikaTripsData && teltonikaTripsData.length > 0 ? (
              <TeltonikaTripsTable data={teltonikaTripsData} expandableRows pagination />
            ) : (
              <p className="text-center">No trip data available.</p>
            )}
          </PreviewCard>
        </Block> */}
        {/* <Block>
          <CardTitle className="left" tag="h5">
            Coach Report
          </CardTitle>
          <Card className="card-bordered card-preview">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                <table style={{ width: "100%", tableLayout: "auto", textAlign: "center" }} className="table">
                  <thead className="table-light">
                    <tr>
                      <th className="d-none d-md-table-cell">Pass</th>
                      <th className="d-none d-md-table-cell">Ticket ID</th>
                      <th className="d-none d-sm-table-cell">Email</th>
                      <th className="d-none d-sm-table-cell">In Time</th>
                      <th className="d-none d-sm-table-cell">Out Time</th>
                      <th className="d-none d-sm-table-cell" onClick={() => handleSort("outtime")}>
                        Out Time {renderSortIcon("outtime")}
                      </th>
                      <th className="d-none d-sm-table-cell">More Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.length > 0
                      ? currentItems.map((item, id) => {
                          return (
                            <tr key={id} className="tb-tnx-item">
                              <td style={{ padding: "0.75rem 0.25rem" }}>
                                <strong>{item.vip}</strong>
                              </td>
                              <td style={{ padding: "0.75rem 0.25rem" }} className="d-none d-md-table-cell">
                                {item.ticketId !== 0 ? (
                                  item.ticketId
                                ) : (
                                  <Badge pill color="danger">
                                    Illegal
                                  </Badge>
                                )}
                              </td>

                              <td style={{ padding: "0.75rem 0.25rem" }} className="d-none d-md-table-cell">
                                {item.emailId}
                              </td>
                              <td style={{ padding: "0.75rem 0.25rem" }} className="d-none d-md-table-cell">
                                <Moment tz="America/New_York" format="MMMM Do YYYY, h:mm a">
                                  {item.intime}
                                </Moment>
                              </td>
                              <td style={{ padding: "0.75rem 0.25rem" }} className="d-none d-md-table-cell">
                                <Moment tz="America/New_York" format="MMMM Do YYYY, h:mm a">
                                  {item.outtime}
                                </Moment>
                              </td>
                              <td style={{ padding: "0.75rem 0.25rem" }} className="d-none d-md-table-cell">
                                <Button
                                  tag="a"
                                  href="#seemore"
                                  color=""
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    onViewClick(item);
                                  }}
                                >
                                  <Icon name="eye"></Icon>
                                </Button>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
                <div className="pagination-container">
                  {data.length > 0 ? (
                    <PaginationComponent
                      itemPerPage={itemPerPage}
                      totalItems={data.length}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  ) : (
                    <div className="text-center">
                      <span className="text-silent">
                        <b>No Data Available</b>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Card>
        </Block> */}
        <Block>
          <Row className="g-gs">
            <Col>
              <CardTitle className="left" tag="h5" style={{ marginTop: 10 }}>
                Coach Report
              </CardTitle>
            </Col>
          </Row>
          <Row>
            <Col className={"mt-2 pl-2"}>
              <UncontrolledDropdown>
                <Alert
                  className="alert-pro"
                  color="danger"
                  style={{ paddingTop: 8, paddingLeft: 15, paddingRight: 15, paddingBottom: 8 }}
                >
                  <div className="custom-control custom-control-sm custom-checkbox">
                    <input
                      type="checkbox"
                      checked={expandData}
                      onClick={handleCheckboxClick}
                      className="custom-control-input form-control"
                      id="expand-data"
                    />
                    <label className="custom-control-label" htmlFor="expand-data">
                      Expand data
                    </label>
                  </div>
                </Alert>
              </UncontrolledDropdown>
            </Col>
          </Row>

          {isLoading ? (
            <Card className="mt-2">
              <div className="nk-ecwg nk-ecwg6">
                <div className="card-inner">
                  <div className="card-title-group" style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <Spinner color="primary" />
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          ) : (
            // Display spinner while loading

            <DataTable
              data={data}
              columns={columns}
              noDataComponent={
                <div className="pt-5 pb-5 text-center text-primary">
                  <b>No Data Found</b>
                </div>
              }
              sortIcon={
                <div>
                  <span>&darr;</span>
                  <span>&uarr;</span>
                </div>
              }
              defaultSortField="timestamp"
              pagination
              expandableRowsComponent={ExpandableRowComponent}
              expandableRows={true}
              expandableRowExpanded={() => expandData}
              paginationTotalRows={data.length}
              paginationPerPage={rowsPerPageS}
              //paginationRowsPerPageOptions={[7, 14, 21, 28]}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              paginationComponent={({ currentPage, rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage }) => (
                <DataTablePagination
                  customItemPerPage={rowsPerPageS}
                  itemPerPage={rowsPerPage}
                  totalItems={rowCount}
                  paginate={onChangePage}
                  currentPage={currentPage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                />
              )}
            ></DataTable>
          )}
        </Block>

        <Modal
          isOpen={viewmore}
          toggle={() => onFormCancel()}
          style={{ zIndex: 1000 }}
          className="modal-dialog-centered"
          size="xl"
        >
          <ModalBody>
            <a href="#seemore" className="close">
              {" "}
              <Icon
                name="cross-sm"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFormCancel();
                }}
              ></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">More Details</h5>
            </div>
            <PreviewCard>
              <Row className="gy-4">
                <Col sm="12">
                  <FormGroup>
                    <label className="form-label" htmlFor="origin">
                      Origin
                    </label>
                    <div className="form-control-wrap">
                      <input
                        //ref={register({ required: true })}
                        className="form-control"
                        type="text"
                        id="origin"
                        name="origin"
                        //onChange={(e) => onInputChange(e)}
                        value={selectedItem?.origin ? selectedItem?.origin : "-"}
                        disabled
                      />
                      {errors.origin && <span className="invalid">This field is required</span>}
                    </div>
                  </FormGroup>
                </Col>
                <Col sm="12">
                  <FormGroup>
                    <label className="form-label" htmlFor="destination">
                      Destination
                    </label>
                    <div className="form-control-wrap">
                      <input
                        //ref={register({ required: true })}
                        className="form-control"
                        type="text"
                        id="destination"
                        name="destination"
                        //onChange={(e) => onInputChange(e)}
                        value={selectedItem?.destination ? selectedItem?.destination : "-"}
                        disabled
                      />
                      {errors.destination && <span className="invalid">This field is required</span>}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </PreviewCard>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};

const columns = [
  {
    name: <div style={{ margin: "0 auto" }}>{"Pass"}</div>,
    selector: (row) => (row.vip ? row.vip : "-"),
    sortable: true,
    grow: 0.5,
    center: true,
  },
  {
    name: <div style={{ margin: "0 auto" }}>{"Ticket ID"}</div>,
    selector: (row) => row.ticketId,
    sortable: true,
    grow: 0.8,
    center: true,
  },
  {
    name: <div style={{ margin: "0 auto" }}>{"Email"}</div>,
    selector: (row) => row.emailId,
    sortable: true,
    grow: 1.5,
    center: true,
  },
  {
    name: <div style={{ margin: "0 auto" }}>{"In Time"}</div>,
    selector: (row) => new Date(row.intime),
    sortable: true,
    sortFunction: (a, b) => new Date(a.intime) - new Date(b.intime),
    cell: (row) =>
      new Date(row.intime).getFullYear() >= 2001 ? <Moment format="MMMM Do YYYY, h:mm a">{row.intime}</Moment> : "-",
    grow: 1,
    center: true,
  },
  {
    name: <div style={{ margin: "0 auto" }}>{"Out Time"}</div>,
    selector: (row) => new Date(row.outtime),
    sortable: true,
    sortFunction: (a, b) => new Date(a.outtime) - new Date(b.outtime),
    cell: (row) =>
      new Date(row.outtime).getFullYear() >= 2001 ? <Moment format="MMMM Do YYYY, h:mm a">{row.outtime}</Moment> : "-",
    grow: 1,
    center: true,
  },
].filter((column) => column !== null);

export default VehicleInfo;
