import axios from "axios";
import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Polyline, useMap, Tooltip } from "react-leaflet";
import { icon } from "leaflet";
import PlaybackParent from "./PlaybackParent";
import redBus from "../../../../assets/images/redbus.png";
import redTruck from "../../../../assets/images/truck.png";
import redCar from "../../../../assets/images/car.png";
import redBike from "../../../../assets/images/motor-sports.png";

import redBus2 from "../../../../assets/images/redBusNew.png";
import greenBus from "../../../../assets/images/greenBus.png";
import yellowBus from "../../../../assets/images/yellowBus.png";
import blackBus from "../../../../assets/images/blackBus.png";

import { Icon, PreviewCard } from "../../../../components/Component";
import { Spinner, Card, CardBody, CardTitle, Button, Modal, ModalBody, Col } from "reactstrap";

const LiveMapTeltonika = ({ DeviceType, count, vehicleType, imei, DeviceMode }) => {
  const VEHICLE_TYPES = { 1: redTruck, 2: redCar, 3: redCar, 4: redBike, 5: redBus, 10: redTruck };
  const [gpsData, setGpsData] = useState();
  const [loading, setLoading] = useState(false);

  const username = localStorage.getItem("username");
  const [polylinePoints, setPolylinePoints] = useState([]);

  const [placeName, setPlaceName] = useState("");

  const [voicesLoaded, setVoicesLoaded] = useState(false);
  const [femaleVoice, setFemaleVoice] = useState(null);

  const [viewPlayback, setViewPlayback] = useState(false);

  let busIcon;
  if (vehicleType === 5) {
    console.log("VehicleType", vehicleType);
    switch (DeviceMode) {
      case "MOVING":
        busIcon = icon({
          iconUrl: greenBus,
          iconSize: [30, 30],
        });
        break;
      case "IDLE":
        busIcon = icon({
          iconUrl: blackBus,
          iconSize: [30, 30],
        });
        break;
      case "PARKED":
        busIcon = icon({
          iconUrl: yellowBus,
          iconSize: [30, 30],
        });
        break;
      case "OFFLINE":
        busIcon = icon({
          iconUrl: redBus2,
          iconSize: [30, 30],
        });
      default:
        busIcon = icon({
          iconUrl: redBus2,
          iconSize: [30, 30],
        });
        break;
    }
  } else {
    busIcon = icon({
      iconUrl: VEHICLE_TYPES[vehicleType],
      iconSize: [30, 30],
    });
  }
  let d = new Date();
  const RecenterAutomatically = ({ lat, lng }) => {
    const map = useMap();
    useEffect(() => {
      map.setView([lat, lng]);
    }, [lat, lng]);
    return null;
  };
  const updateMsg = loading
    ? "Updating"
    : "Last updated at " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
  const getlastGpsdata = () => {
    setLoading(true);

    let apiUrl;
    if (username === "ZED Demo" || username === "testdemo") {
      apiUrl = `https://panel-config.zed-admin.com/api/getlastGpsdata?imei=${imei}`;
    } else {
      apiUrl = `${process.env.REACT_APP_CONFIG_BASE_URL}getlastGpsdata?imei=${imei}`;
    }

    axios
      .get(apiUrl)
      .then((res) => {
        setGpsData(res.data);
        const newPoint = { lat: res.data.latitude, lng: res.data.longitude };
        setPolylinePoints((prevPoints) => [...prevPoints, newPoint]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchPlaceName = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&zoom=5&addressdetails=1`,
        {
          headers: {
            "User-Agent": "",
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data && data.display_name) {
        setPlaceName(data.display_name);
        speakName(data.display_name.split(",")[0]);
        console.log("Place Name:", data.display_name.split(",")[0]);
      } else {
        setPlaceName("Unknown Location");
      }
    } catch (error) {
      console.error("Error fetching place name:", error);
      setPlaceName("Unknown Location");
    }
  };

  //Speak current place name
  const loadVoices = () => {
    const voices = window.speechSynthesis.getVoices();
    const femaleVoices = voices.filter(
      (voice) => voice.name.toLowerCase().includes("female") || voice.gender === "female"
    );
    if (femaleVoices.length > 0) {
      setFemaleVoice(femaleVoices[0]);
    }
    setVoicesLoaded(true);
  };

  const speakName = (placename) => {
    if (!voicesLoaded) {
      return;
    }

    const utterance = new SpeechSynthesisUtterance(placename);
    utterance.voice = femaleVoice;
    window.speechSynthesis.speak(utterance);
  };

  const onFormCancel = () => {
    setViewPlayback(false);
  };

  useEffect(() => {
    if (typeof window.speechSynthesis !== "undefined") {
      loadVoices();
      window.speechSynthesis.onvoiceschanged = loadVoices;
    }
  }, []);

  useEffect(() => {
    getlastGpsdata();
    const timer = setInterval(() => {
      getlastGpsdata();
    }, 10000);

    return () => clearInterval(timer);
  }, []);

  return (
    <React.Fragment>
      <PreviewCard>
        <div style={{ height: "5px" }} className="d-flex align-items-center justify-content-between">
          <div>{updateMsg}</div>
          <div className="d-flex align-items-center">
            <div>
              {placeName && (
                <Button onClick={() => speakName(placeName.split(",")[0])} className="btn-dim" color="danger">
                  <Icon name="map-pin-fill" size="md" color="primary" className="mr-1" />
                  {placeName}
                </Button>
              )}
            </div>
            <div className="ml-2">
              {
                <Button onClick={() => setViewPlayback(true)} className="btn-dim" color="primary">
                  <Icon style={{ fontWeight: "bolder" }} name="update" size="md" color="primary" className="mr-1" />
                  Playback
                </Button>
              }
            </div>
          </div>
        </div>
      </PreviewCard>

      <div className="leaflet-container shadow" style={{ height: "356px" }}>
        {gpsData && gpsData.latitude && gpsData.longitude ? (
          <MapContainer center={[gpsData.latitude, gpsData.longitude]} zoom={9}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <RecenterAutomatically lat={gpsData.latitude} lng={gpsData.longitude} />
            {(DeviceType === 3 || DeviceType === 4) && (
              <div className="leaflet-top leaflet-right">
                <div className="leaflet-control leaflet-bar" style={{ border: "none" }}>
                  <div style={{ width: "180px" }}>
                    <Card>
                      <CardBody className="card-inner d-flex align-items-center justify-content-between py-1 px-2">
                        <CardTitle className="text-primary" tag="h6">
                          Realtime Occupancy
                        </CardTitle>
                        <CardTitle className="center ff-mono" tag="h4">
                          {count}
                        </CardTitle>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </div>
            )}

            {/* <Marker position={[gpsData.latitude, gpsData.longitude]} icon={busIcon}></Marker> */}
            <Marker
              position={[gpsData.latitude, gpsData.longitude]}
              icon={busIcon}
              eventHandlers={{
                click: () => {
                  fetchPlaceName(gpsData.latitude, gpsData.longitude);
                },
              }}
            >
              {/* <Tooltip direction="top" style={{ padding: "2px", fontSize: "14px", height: "10px" }}>
                {
                  <div>
                    <label style={{ fontWeight: "bold" }}>
                      {placeName}
                    </label>
                  </div>
                }
              </Tooltip> */}
            </Marker>

            <Polyline positions={polylinePoints} />
          </MapContainer>
        ) : loading ? (
          <div className="text-center mt-5">
            <Spinner />
          </div>
        ) : (
          <div className="text-center mt-5" style={{ fontSize: "16px" }}>
            Try again later
          </div>
        )}
      </div>

      <Modal
        isOpen={viewPlayback}
        toggle={() => onFormCancel()}
        style={{ zIndex: 1000 }}
        className="modal-dialog-centered"
        size="xl"
      >
        <ModalBody>
          <a href="#seemore" className="close">
            {" "}
            <Icon
              name="cross-sm"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
            ></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Playback</h5>
          </div>
          <Col sm="12" className="mt-2 mb-3">
            <div style={{ width: "100%", height: "400px" }}>
              <PlaybackParent imei={imei} />
            </div>
          </Col>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default LiveMapTeltonika;
